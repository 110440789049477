<template>
  <div class="container-fluid pt-3">
    <div v-if="currentUser.role === 0" class="d-flex flex-column justify-content-center align-items-center">
      <button @click="startGeocoding" class="btn btn-success">Start</button>
      <div v-if="currentId" class="alert alert-info mt-4" role="alert">{{ currentId }}</div>
    </div>
    <div v-else class="m-3 alert alert-danger text-center">You do not have permission to perform this action.</div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import storeConfig from "../../store/config";
import { useApiQuery } from "@/composables/useApiQueries";
import "leaflet/dist/leaflet.css";
export default {
  name: "AddressesGeocode",
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.getters.user);
    const currentId = ref(null);

    const { result: addresses, query: getAddresses } = useApiQuery({
      url: "search/addresses/nogeocode",
      defaultVal: [],
    });

    async function setGeocoding(address) {
      if (address.lat === null && address.lon === null && address.city && address.country) {
        currentId.value = address.id;
        try {
          let result = await axios.get("https://nominatim.openstreetmap.org", {
            params: {
              street: address.street,
              city: address.city,
              country: address.country.name,
              format: "json",
            },
            // important, otherwhise Authorization headers will be sent:
            transformRequest: [
              (data, headers) => {
                delete headers.common.Authorization;
                return data;
              },
            ],
          });
          if (result.data[0].lat.length) {
            address.lat = result.data[0].lat;
            address.lon = result.data[0].lon;
            await axios.put(storeConfig.apiBaseUrl + "addresses/" + address.id, {
              lat: result.data[0].lat,
              lon: result.data[0].lon,
            });
          }
        } catch (err) {
          // console.log(err.response);
        }
      }
    }

    const addressCounter = ref(0);
    async function startGeocoding() {
      await setTimeout(() => {
        // && addressCounter.value < 5000
        if (addressCounter.value < addresses.value.length) {
          setGeocoding(addresses.value[addressCounter.value]);
          addressCounter.value++;
          startGeocoding();
        } else {
          currentId.value = "End";
        }
      }, 1500);
    }

    getAddresses();

    return {
      currentUser,
      addresses,
      startGeocoding,
      setGeocoding,
      currentId,
    };
  },
};
</script>
